/* Responsive Breakpoints */
$screen: 1170px;
$tablet: 1024px;
$phone: 720px;
$smallphone: 500px;

/* Variables */
$grid_padding: 5%;

/* Fonts */

$serif: 'Roboto', serif;
$sans :'Montserrat', sans-serif;

/* Colors */

/* Max Feedback */
$darkgray: #516166;
$litegrey: #ecedf1;
$yellow: #ffd04e;
$text: #7f889c;
$gray: #99a0b0;
$blue: #2f3a3e;
$liteblue: #26b7f1;
$liteblue-hover: #0596e5;
$purple: #8866cd;
$green: #07dda2;

/* Gradients */
$gradient-blue-dark: rgba(4,146,228,0.96);
$gradient-blue-light: rgba(24,206,251,0.96);
$gradient-green-dark: #0ceb93;
$gradient-green-light: #02cfaf;

/* RPM */
$red: #DF403D;
/*$liteblue: $red;
$green: $darkgray;
$liteblue-hover: darken($red,10);
$gradient-red-dark: $red;
$gradient-red-light: $red;
$gradient-blue-dark: $gradient-red-dark;
$gradient-blue-light: $gradient-red-light;
$gradient-green-dark: $gradient-red-dark;
$gradient-green-light: $gradient-red-light;*/


/* ANIMATION */
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$animation-curve-default: $animation-curve-fast-out-slow-in !default;