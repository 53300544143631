/* Body */

body { 
    
    img, iframe{
        max-width: 100%;
        max-height: 100%;
    }
    
    p, ul, ol{
        color: $text;
        font-family: $sans;
        font-size: .89em;
    }
    
    a{
        cursor: pointer;
    }
    
    h1,h2,h3,h4,h5{
        text-transform: uppercase;
        color: $darkgray;
        font-family: $sans;
        font-weight: bold;
        letter-spacing: 1px;
    }
    
    &.back-end{
        #page-wrap{
            margin-left: 300px;
            @include respond_to($screen){
                margin-left: 0;
            }
        }
    
        h2{
            font-weight: bold;
            font-size: 1.5em;
        }

        h3{
            color: $blue;
            font-size: 1em;
        }

        h4{
            margin: 0;
            font-size: .95em;
            letter-spacing: 1px;
            
            &.lighten{
                color: #7f889c;
            }
        }

        h5{
            margin: .25em 0;
            font-size: .95em;
            font-weight: normal;
            color: darken($litegrey,15);
            text-transform: initial;
        }
    }
    
    h1{
        font-size: 3.2em; 
        @include respond_to($phone){
            font-size: 2.1em;
        }
        margin: 0;
        
        &.blue-gradient{
            background: -webkit-linear-gradient(right,$gradient-blue-light, $gradient-blue-dark);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &.green-gradient{
            background: -webkit-linear-gradient(right, $gradient-green-dark, $gradient-green-light);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    
    h2{
        margin: .25em 0;
        font-size: 2.3em;
        letter-spacing: 2px;
        @include respond_to($phone){
            font-size: 1.9em;
        }
    }
    
    h3{
        margin: .2em 0;
        font-size: 1.6em;
    }
    
    hgroup{
        text-align: center;
    }
    
    .hidden{
        display: none !important;
    }
    
    .white-container{
        background-color: #fff;
        border-radius: 2px;
        border: 2px solid $litegrey;
        padding: 50px 10%;
        /*box-shadow: 5px 3px 10px #ecedf1;*/
    }
    
    .star-rating{
        
        svg{
            path{
                fill: $yellow;
            }
        }
        
        &.medium{
            svg{
                height: 30px; width: 30px;
            }
        }
        
        &.small{
            svg{
                height: 20px; width: 20px;
            }
        }
    }
    
    .featherlight{
        z-index: 10000;
        
        .featherlight-close-icon{
            font-weight: bold;
            font-size: 1.3em;
            margin-top: 6px;
            margin-right: 6px;
            line-height: 35px !important;
            width: 35px !important;
            color: $gray !important;
        }

        .featherlight-content, .sweet-alert{
            padding: 50px 80px !important;
            @include respond_to($phone){ padding: 20px 30px !important; }
            border-radius: 2px;
            border-bottom: 0 !important;
            font-family: $sans;
            color: $text;
            max-width: 100%;
            $icon-height: 30px;
            $padding: 15px;

            form{ width: 450px; max-width: 95%; min-width: 100%; }
            h1{
                text-align: center;
                margin: 0;
                margin-bottom: 20px;
                background: -webkit-linear-gradient(right,$gradient-blue-light, $gradient-blue-dark);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            h3{
                text-align: center;
                color: $gray;
                opacity: .7;
            }

            .row{
                text-align: center;
                margin: 10px 0;
                overflow: initial;

                .field-row{
                    display: inline-block;
                    width: 100%;

                    &.two-col{
                        .field{
                            display: inline-block;
                            &:first-child{
                                padding-right: $padding;
                            }
                        }
                    }
                }

                a{
                    color: $gray;
                }

                .btn{
                    margin-top: 10px;

                    &.full{
                        width: 92%;
                    }
                }
            }

            p{
                color: $text;
                line-height: 1.5;
            }

            iframe{
                height: 400px;
                width: 600px;
                @include respond_to($tablet){
                    height: 250px;
                }
            }
        }
    }
    
    .modal{
        .modal-content{
            padding: 50px 80px !important;
            @include respond_to($phone){ padding: 20px 30px !important; }
            border-radius: 2px;
            border-bottom: 0 !important;
            font-family: $sans;
            color: $text;
            max-width: 100%;
        }
            
        button.close{
            border: none;
            background-color: #fff;
            position: absolute;
            right: 4px;
            top: 4px;
            font-size: 1.7em;
            color: $gray;
        }
        
        .modal-header{
            border-bottom: none;
            padding: 0;
            
            h1{
                text-align: center;
                margin: 0;
                margin-bottom: 20px;
                background: -webkit-linear-gradient(right,$gradient-blue-light, $gradient-blue-dark);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        
        .modal-body{
            padding: 0;
        }
        
        .btn.full{
            max-width: 100%;
        }
    }
    
    &.back-end{
        .sidebar-menu{
            .close-icon{
                display: none;
                @include respond_to($screen){
                    display: block;
                }
            }
        }
    }
    
    /* Float */
    .hvr-float {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px transparent;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    
    .checkbox{
        display: table;
        margin: 35px auto;

        .roundedOne {
            display: table-cell;
            width: 35px;
            height: 35px;
            position: relative;
            margin: 20px auto;
            background: #fff;
            border-radius: 50px;
            label {
              width: 30px;
              height: 30px;
              position: absolute;
              top: 4px;
              left: 4px;
              cursor: pointer;
              background-color: #fff;
              border-radius: 50px;
              border: 3px solid #ced0d3;
              &:after {
                content: '';
                width: 12px;
                height: 6px;
                position: absolute;
                top: 6px;
                left: 5px;
                border: 3px solid #ced0d3;
                border-top: none;
                border-right: none;
                background: transparent;
                opacity: 0;
                transform: rotate(-45deg);
              }
              &:hover::after {
                opacity: 0.3;
              }
            }
            input[type=checkbox] {
              visibility: hidden;
              &:checked + label:after {
                opacity: 1;
              } 
            }
          }

        p{
            display: table-cell;
            vertical-align: middle;
            letter-spacing: 1px;
            font-weight: normal;
            font-family: $serif;
            font-size: 1.1em;
            padding-left: 20px;
        }
    }
    
    .typed-cursor{
        opacity: 1;
        color: #fff;
        font-size: 4em;
        -webkit-animation: blink 0.7s infinite;
        -moz-animation: blink 0.7s infinite;
        animation: blink 0.7s infinite;
    }
    @keyframes blink{
        0% { opacity:1; }
        50% { opacity:0; }
        100% { opacity:1; }
    }
    @-webkit-keyframes blink{
        0% { opacity:1; }
        50% { opacity:0; }
        100% { opacity:1; }
    }
    @-moz-keyframes blink{
        0% { opacity:1; }
        50% { opacity:0; }
        100% { opacity:1; }
    }
    
    .blue-to-white-gradient{
        background: $gradient-blue-dark;
        background: -moz-linear-gradient(left, $gradient-blue-dark 0%, $gradient-blue-light 50%, rgba(252,249,249,0.96) 50%, #fff 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, $gradient-blue-dark), color-stop(50%, $gradient-blue-light), color-stop(50%, #fff), color-stop(100%, #fff));
        background: -webkit-linear-gradient(left, $gradient-blue-dark 0%, $gradient-blue-light 50%, #fff 50%, #fff 100%);
        background: -o-linear-gradient(left, $gradient-blue-dark 0%, $gradient-blue-light 50%, #fff 50%, #fff 100%);
        background: -ms-linear-gradient(left, $gradient-blue-dark 0%, $gradient-blue-light 50%, #fff 50%, #fff 100%);
        background: linear-gradient(to right, $gradient-blue-dark 0%, $gradient-blue-light 50%, #fff 50%, #fff 100%);
        
        @include respond_to($phone){
            background: none;
            
            .column{
                &:first-child{
                    background: $gradient-blue-dark;
                }
            }
        }
    }
    
    .blue-gradient{
        background: $gradient-blue-dark;
        background: -webkit-linear-gradient(right, $gradient-blue-light, $gradient-blue-dark); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(right, $gradient-blue-light, $gradient-blue-dark); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(right, $gradient-blue-light, $gradient-blue-dark); /* For Firefox 3.6 to 15 */
        background: linear-gradient(right, $gradient-blue-light, $gradient-blue-dark); /* Standard syntax */
    }
    
    .green-gradient{
        background: #07dda1;
        background: -webkit-linear-gradient(right, $gradient-green-dark, $gradient-green-light); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(right, $gradient-green-dark, $gradient-green-light); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(right, $gradient-green-dark, $gradient-green-light); /* For Firefox 3.6 to 15 */
        background: linear-gradient(right, $gradient-green-dark, $gradient-green-light); /* Standard syntax */
    }
    
    /* Tooltip text */
    .tooltiptext {
        visibility: hidden;
        width: 100%;
        background-color: $gray;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        margin-top: 5px;
        
        p{
            color: #fff !important;
        }
        
        &:before{
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $gray transparent;
        }
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover + .tooltiptext {
        visibility: visible;
    }
}