input[type="submit"],
.btn,
.confirm,
.cancel,
.paginate_button {
    padding: 14px 20px;
    min-width: 225px;
    text-align: center;
    color: #fff;
    background-color: $green;
    display: inline-block;
    text-decoration: none;
    font-family: $sans;
    font-size: 14px;
    line-height: 1;
    transition: all 0.3s ease;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    position: relative;
    transition: all 0.3s ease;
    border-radius: 5px;
    transform: translateZ(0);

    &:focus {
        text-transform: uppercase;
    }

    &.green-gradient,
    &.blue-gradient {
        padding: 16px 22px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0%;
        background-color: rgba($gray, 0.2);
        overflow: hidden;
        margin-right: -1px;
        z-index: -1;
        transition: all 0.4s ease;
        border-radius: 5px;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }

    &.green-inverse {
        background-color: #fff;
        border: 2px solid $green;
        color: $green;
    }

    &.white {
        background-color: #fff;
        border: 2px solid #fff;
        color: $liteblue;

        &:after {
            background-color: $litegrey;
        }
    }

    &.transparent {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.6);
        color: #fff;

        &:hover {
            border: 2px solid #fff;
            color: #fff;
        }
        &:after {
            background-color: rgba($litegrey, 0.15);
        }
    }

    &.blue {
        background: $blue;
        color: #fff;
        &:after {
            background-color: darken($blue, 10);
        }
    }

    &.blue-inverse {
        background: #fff;
        color: $blue;
        &:after {
            background-color: rgba($blue, 0.15);
        }
    }

    &.purple {
        background: $purple;
        color: #fff;
        &:after {
            background-color: darken($purple, 10);
        }
    }

    &.purple-inverse {
        background: #fff;
        color: $purple;
        &:after {
            background-color: rgba($purple, 0.15);
        }
    }

    &.navy {
        background-color: $darkgray;
        border: 2px solid $darkgray;
        &:hover {
            background-color: darken($darkgray, 15);
        }
    }

    &.liteblue {
        background-color: $liteblue;
        border: 2px solid $liteblue;
        &:after {
            background-color: rgba($litegrey, 0.15);
        }
    }

    &.gray {
        background-color: darken($litegrey, 15);
        border: 2px solid darken($litegrey, 15);
        &:hover {
            background-color: darken($litegrey, 25);
        }
    }

    &.gray-inverse {
        background: #fff;
        color: $gray;
        border: 2px solid $gray;
        &:after {
            background-color: rgba($litegrey, 0.5);
        }
    }

    &.red {
        background: $red;
        color: #fff;
        border: 2px solid $red;
        &:after {
            background-color: rgba($litegrey, 0.5);
        }
    }

    &.red-inverse {
        background: #fff;
        color: $red;
        border: 2px solid $red;
        &:after {
            background-color: rgba($litegrey, 0.5);
        }
    }

    &.full {
        width: 100%;
        max-width: 92%;
    }

    &.half {
        width: 48%;
        padding: 16px 5px;
        min-width: initial;
        @include respond_to($phone) {
            width: 100%;
        }

        + .half {
            float: right;
        }
    }

    &.large {
        padding: 16px 65px;
    }

    &.small {
        padding: 10px 16px;
        font-size: 12px;
    }

    &.loading {
        color: transparent;
        cursor: not-allowed;

        &:after {
            content: "";
            border: 8px solid #f3f3f3; /* Light grey */
            border-top: 8px solid $liteblue; /* Blue */
            border-radius: 50%;
            width: 16px;
            height: 16px;
            animation: spin 2s linear infinite;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .spinner {
        display: inline-block;
        opacity: 0;
        width: 0;
        -webkit-transition: opacity 0.25s, width 0.25s;
        -moz-transition: opacity 0.25s, width 0.25s;
        -o-transition: opacity 0.25s, width 0.25s;
        transition: opacity 0.25s, width 0.25s;
    }

    &.active {
        cursor: progress;
    }

    &.active .spinner {
        opacity: 1;
        width: auto;
    }

    &.active .spinner {
        min-width: 20px;
    }

    &.toggle-off {
        background: #fff;
        color: $litegrey;
        border: 2px solid $litegrey;
        &:after {
            background-color: rgba($litegrey, 0.15);
        }
    }

    &.toggle-on {
    }
}

.cancel {
    background-color: #fff;
    border: 2px solid $green;
    color: $green;
}

.cancel,
.confirm {
    padding: 16px 22px;
}

.confirm {
    background: #07dda1;
    background: -webkit-linear-gradient(
        right,
        $gradient-green-dark,
        $gradient-green-light
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        right,
        $gradient-green-dark,
        $gradient-green-light
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        right,
        $gradient-green-dark,
        $gradient-green-light
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        right,
        $gradient-green-dark,
        $gradient-green-light
    ); /* Standard syntax */
}

.btn-settings {
    float: right;
    color: darken($litegrey, 5);
    font-size: 25px;
    padding: 10px;
    text-align: center;
}
