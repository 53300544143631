.field {
    text-transform: uppercase;
    font-family: $sans;
    margin-bottom: 20px;
    position: relative;

    a.external-link {
        color: darken($litegrey, 10);
        position: absolute;
        right: 15px;
        top: 50%;
    }
}

input[type="checkbox"] {
    display: inline-block;
    width: auto;
}

input[type="url"] {
    padding-right: 40px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.radios {
    li {
        clear: both;

        label.radio {
            display: inline-block;
            float: left;

            input {
                display: inline-block;
                width: auto;
                margin-right: 5px;
            }
        }
    }
}

.field-row {
    &.two-col {
        .mdl-textfield {
            width: 42.5%;
            @include respond_to($phone) {
                width: 92%;
            }

            &:first-child {
                margin-right: 7%;
                @include respond_to($phone) {
                    margin-right: 0;
                }
            }

            &:after {
                right: -14%;
                @include respond_to($phone) {
                    right: -5%;
                }
            }
        }
    }
}

form {
    .btn {
        border: none;
    }
}

.select-star-rating {
    input {
        display: none;
    }

    label {
        display: inline-block;

        svg {
            height: 30px;
            width: 30px;

            path {
                fill: #fff;
                stroke: $gray;
            }
        }

        &.active {
            svg {
                path {
                    fill: $yellow;
                    stroke: $yellow;
                }
            }
        }

        &:hover {
            svg {
                path {
                    fill: $yellow;
                    stroke: $yellow;
                }
            }
        }
    }

    input[type="checkbox"]:checked + label {
        svg {
            path {
                fill: $yellow;
            }
        }
    }
}

$input-text-font-size: 16px;
$input-text-color: $gray;
$input-text-button-size: 6px;
$input-text-width: 100% !default;
$input-text-padding: 4px !default;
$input-text-vertical-spacing: 20px !default;

$input-text-floating-label-fontsize: 14px !default;
$input-text-expandable-icon-top: 16px !default;
$input-text-bottom-border-color: $gray;

$input-text-background-color: transparent !default;
$input-text-label-color: $gray !default;

$input-text-highlight-color: $liteblue !default;
$input-text-disabled-color: $litegrey !default;
$input-text-disabled-text-color: $litegrey !default;
$input-text-error-color: $gray !default;

.mdl-textfield {
    position: relative;
    font-size: $input-text-font-size;
    display: inline-block;
    box-sizing: border-box;
    width: 92%;
    max-width: 92%;
    margin: 0;
    font-family: $sans;
    letter-spacing: 1px;
    padding: $input-text-vertical-spacing 0;
    transition: all 0.3s ease;

    // Align buttons, if used.
    & .mdl-button {
        position: absolute;
        bottom: $input-text-vertical-spacing;
    }

    &.white {
        input,
        textarea {
            color: #fff !important;
            border-bottom: 2px solid #fff;
        }

        select {
            color: $darkgray;
            border-bottom: 2px solid #fff;
        }

        label {
            color: #fff !important;
            &:after {
                background-color: #fff !important;
            }
        }
    }

    &:after {
        content: "\f00c";
        font-family: FontAwesome;
        font-size: 1.25em;
        position: absolute;
        bottom: 30px;
        right: -8%;
        opacity: 0;
        transition: all 0.6s ease;
        background: -webkit-linear-gradient(
            right,
            $gradient-green-light,
            $gradient-green-dark
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include respond_to($phone) {
            right: -5%;
        }
    }

    &.is-valid {
        &:after {
            opacity: 1;
        }

        &.is-invalid {
            &:after {
                opacity: 0;
            }
        }
    }
}

// Optional class to align right.
.mdl-textfield--align-right {
    text-align: right;
}

// Optional class to display at full width.
.mdl-textfield--full-width {
    width: 100%;
}

// Optional class to make the text field expandable.
.mdl-textfield--expandable {
    min-width: $input-text-button-size;
    width: auto;
    min-height: $input-text-button-size;

    // Align icon button
    .mdl-button--icon {
        top: $input-text-expandable-icon-top;
    }
}

// Styling for the input element.
.mdl-textfield__input {
    border: none;
    border-bottom: 2px solid $input-text-bottom-border-color;
    display: inline-block;
    font-size: $input-text-font-size;
    font-family: $sans;
    margin: 0;
    padding: $input-text-padding 0;
    width: $input-text-width;
    background: none;
    text-align: left;
    color: $input-text-color;

    &[type="number"] {
        -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .mdl-textfield.is-focused & {
        outline: none;
    }

    .mdl-textfield.is-invalid & {
        border-color: $input-text-error-color;
        box-shadow: none;
    }

    fieldset[disabled] .mdl-textfield &,
    .mdl-textfield.is-disabled & {
        background-color: transparent;
        border-bottom: 1px dotted $input-text-disabled-color;
        color: $input-text-disabled-text-color;
    }
}

.mdl-textfield textarea.mdl-textfield__input {
    display: block;
}

// Styling for the label / floating label.
.mdl-textfield__label {
    bottom: 0;
    color: $input-text-label-color;
    font-size: $input-text-font-size;
    left: 0;
    right: 0;
    opacity: 0.7;
    font-family: $sans;
    pointer-events: none;
    position: absolute;
    display: block;
    top: ($input-text-padding + $input-text-vertical-spacing);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;

    .mdl-textfield.is-dirty &,
    .mdl-textfield.has-placeholder & {
        visibility: hidden;
    }

    // Floating Label
    .mdl-textfield--floating-label & {
        @include material-animation-default();
    }

    .mdl-textfield--floating-label.has-placeholder & {
        transition: none;
    }

    fieldset[disabled] .mdl-textfield &,
    .mdl-textfield.is-disabled.is-disabled & {
        color: $input-text-disabled-text-color;
    }

    .mdl-textfield--floating-label.is-focused &,
    .mdl-textfield--floating-label.is-dirty &,
    .mdl-textfield--floating-label.has-placeholder & {
        color: $input-text-highlight-color;
        font-size: $input-text-floating-label-fontsize;
        top: $input-text-vertical-spacing - ($input-text-floating-label-fontsize +
                    $input-text-padding);
        visibility: visible;
        opacity: 1;
    }

    .mdl-textfield--floating-label.is-focused
        .mdl-textfield__expandable-holder
        &,
    .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder &,
    .mdl-textfield--floating-label.has-placeholder
        .mdl-textfield__expandable-holder
        & {
        top: -($input-text-floating-label-fontsize + $input-text-padding);
    }

    .mdl-textfield--floating-label.is-invalid & {
        color: $input-text-error-color;
        font-size: $input-text-floating-label-fontsize;
    }

    // The after label is the colored underline for the TextField.
    &:after {
        background-color: $input-text-highlight-color;
        bottom: $input-text-vertical-spacing;
        content: "";
        height: 2px;
        left: 45%;
        position: absolute;
        @include material-animation-default();
        visibility: hidden;
        width: 10px;
    }

    .mdl-textfield.is-focused &:after {
        left: 0;
        visibility: visible;
        width: 100%;
    }

    .mdl-textfield.is-invalid &:after {
        background-color: $input-text-error-color;
    }
}

// TextField Error.
.mdl-textfield__error {
    color: $input-text-error-color;
    position: absolute;
    font-size: $input-text-floating-label-fontsize;
    margin-top: 3px;
    visibility: hidden;
    display: block;

    .mdl-textfield.is-invalid & {
        visibility: visible;
    }
}

// Expandable Holder.
.mdl-textfield__expandable-holder {
    display: inline-block;
    position: relative;
    margin-left: $input-text-button-size;

    @include material-animation-default();
    display: inline-block;

    // Safari (possibly others) need to be convinced that this field is actually
    // visible, otherwise it cannot be tabbed to nor focused via a <label>.
    // TODO: In some cases (Retina displays), this is big enough to render the
    // inner element :(
    max-width: 0.1px;

    .mdl-textfield.is-focused &,
    .mdl-textfield.is-dirty & {
        // This is an unfortunate hack. Animating between widths in percent (%)
        // in many browsers (Chrome, Firefox) only animates the inner visual style
        // of the input - the outer bounding box still 'jumps'.
        // Thus assume a sensible maximum, and animate to/from that value.
        max-width: 600px;
    }
    .mdl-textfield__label:after {
        bottom: 0;
    }
}

// One variable to rule them all :)

// All dimentions are based on this one variable; Change it's value and see everythinmg else change accordingly.

$width: 80px; // Change Me
$height: $width / 2.5;

.card {
    display: inline-block;
    margin: (($height * 1.4 - $height) / 2) + 5px;
    margin: (($height * 1.5 - $height) / 2);
    width: $width;
    height: $height;
    text-align: center;
    position: relative;

    input[type="checkbox"] {
        display: none;

        &:checked ~ .toggle {
            background: $green;
            left: ($width - ($height * 1.4 - $height) / 2) - $height;
            transition: 0.5s;
            &:after {
                content: "On";
                font-family: $sans;
                position: absolute;
                top: 13px;
                left: 0;
                right: 0;
                margin: auto;
                color: #fff;
            }
        }

        &:checked ~ .switch {
            background: darken($green, 15);
            transition: 0.5s;
        }
    }

    .switch {
        display: block;
        width: $width;
        height: $height;
        background: $gray;
        border-radius: $height / 2;
        position: absolute;
        top: 0;
        transition: 0.5s;
    }

    .toggle {
        height: $height * 1.4;
        width: $height * 1.4;
        border-radius: 50%;
        background: white;
        position: absolute;
        top: ($height * 1.4 - $height) / -2;
        left: ($height * 1.4 - $height) / -2;
        box-shadow: 0 $width / 50 $width / 25 rgba(black, 0.4);
        transition: 0.5s;

        &:after {
            content: "Off";
            font-family: $sans;
            position: absolute;
            top: 13px;
            left: 0;
            right: 0;
            margin: auto;
            color: $gray;
        }
    }
}
