/*!
Theme Name: MaxFeedback
Theme URI: http://www.company119.com
Description:
Author: Company 119
Author URI: http://www.company119.com
Version: 1.0
Template: co119-boilerplate
*/

@import "vendor/normalize";

@import "init/vars";
@import "init/mixins";
@import "init/base";

@import "global";
@import "header";
@import "hero";
@import "content";
@import "footer";
@import "modules/modules";

@import 'https://fonts.googleapis.com/css?family=Montserrat:400,
    700|Roboto:400,
    700';
