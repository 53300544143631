/* Header */

header.main { 
    font-family: $sans;
    padding: 15px 0;
    z-index: 1000;
    
    &.back-end{
        text-align: center;
        color: $gray;
        border-bottom: 2px solid $litegrey;
        box-shadow: 5px 3px 10px $litegrey;
        width: 100%;
        
        .menu-toggle-button{
            @include respond_to($screen){
                display: table;
            }
        }
        
        .logo{
            margin: auto;
            position: absolute;
            left: 0; right: 0;
            height: 40px;
        }
        
        @include respond_to($phone){
            width: initial;
            float: initial;
        }
        
        .user-profile{
            display: inline-block;
            float: right;
            padding-left: 15px;
            margin-top: 7px;
            position: relative;
            z-index: 10;
            
            .log{
                color: $gray;

                .team-icon{
                    border: 2px solid #e4e4e4;
                    opacity: .6;

                    svg{
                        fill:#c2c4c6;
                    }
                }

                .text{
                    color: #c2c4c6;
                }

                &:hover{
                    .team-icon{
                        border-color: #3d3a42;

                        svg{
                            fill:#3d3a42;
                        }
                    }
                }
            }
        }
    }
    
    &.front-end{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 75px;
        padding: 35px 0;
        transition: .5s ease all;
        font-size: .9em;
        
        &.fixed{ 
            background-color: #fff;
            padding: 15px 0;
            position: fixed;
            
            ul.menu{

                li{

                    a{
                        color: lighten($liteblue,20) !important;
                    }

                    &.current-menu-item{
                        a{
                            color: $liteblue !important;
                        }
                    }

                    &:hover{
                        a{
                            color: $liteblue-hover !important;
                        }
                    }
                }
            }
        
            .user-profile{

                .log{
                    color: lighten($liteblue,20) !important;

                    .team-icon{
                        border: 2px solid lighten($liteblue,20) !important;

                        svg{
                            fill: lighten($liteblue,20) !important;
                        }
                        
                        i{
                            color: lighten($liteblue,20) !important;
                        }
                    }

                    .text{
                        color: lighten($liteblue,20) !important;
                    }
                }

                &:hover{
                    color: $liteblue-hover !important;

                    .team-icon{
                        border: 2px solid $liteblue-hover !important;

                        svg{
                            path{
                                fill: $liteblue-hover !important;
                            }
                            
                            i{
                                color: $liteblue-hover !important;
                            }
                        }
                    }

                    .text{
                        color: $liteblue-hover !important;
                    }
                }
            }
        }
        
        .logo{
            height: 40px;
        }
        
        .menu-toggle-button{
            @include respond_to($tablet){
                display: table;
                color: $liteblue;
                position: absolute;
                right: 0; top: 0;
            }
        }
        
        ul.menu{
            position: absolute;
            left: 60%;
            right: 0;
            margin: 0;
            top: 14px;
            letter-spacing: .5px;
            display: block;
            font-size: .9em;
            text-transform: uppercase;
            @include respond_to($tablet){
                display: none;
                background-color: #fff;
                right: -5%; top: 100%;
                padding: 5%;
                font-size: 1.2em;
            }
            @include respond_to($phone){
                left: 50%;
            }
            
            li{
                float: left;
                margin: 0;
                position: relative;
                padding-right: 25px;
                &:last-child{ padding-right: 0; }
                @include respond_to($tablet){
                    display: list-item;
                    float: none;
                    text-align: right;
                    padding-right: 0;
                }
                
                a{
                    color: lighten($liteblue,20);
                    text-decoration: none;
                    @include respond_to($tablet){
                        padding: 10px 0;
                        display: block;
                    }
                }
                
                &.current-menu-item{
                    a{
                        color: $liteblue;
                    }
                }
                
                &:hover{
                    a{
                        color: $liteblue-hover;
                    }
                }
                
                &.user-profile{
                    position: absolute;
                    right: 0;
                    top: -3px;
                    @include respond_to($tablet){
                        position: relative;
                    }

                    .log{
                        @include respond_to($tablet){
                            padding-left: 0;
                        }
                        color: lighten($liteblue,20);

                        .team-icon{
                            border: 2px solid lighten($liteblue,20);

                            svg{
                                fill: lighten($liteblue,20);
                            }
                            
                            i{
                                color: lighten($liteblue,20);
                            }
                        }

                        .text{
                            color: lighten($liteblue,20);
                        }
                    }

                    &:hover{
                        color: $liteblue-hover;

                        .team-icon{
                            border: 2px solid $liteblue-hover;

                            svg{
                                fill: $liteblue-hover;
                            }
                            
                            i{
                                color: $liteblue-hover;
                            }
                        }

                        .text{
                            color: $liteblue-hover;
                        }
                    }
                }
            }
        }
        
        &.sub-page{
            ul.menu{

                li{

                    a{
                        color: rgba(255,255,255,.7);
                    }

                    &.current-menu-item{
                        a{
                            color: #fff;
                        }
                    }

                    &:hover{
                        a{
                            color: #fff;
                        }
                    }
                }
            }
            
            .user-profile{

                .log{
                    color: rgba(255,255,255,.7);

                    .team-icon{
                        border: 2px solid rgba(255,255,255,.7);

                        svg{
                            fill: rgba(255,255,255,.7);
                        }
                    }

                    .text{
                        color: rgba(255,255,255,.7);
                    }
                }

                &:hover{
                    color: #fff;

                    .team-icon{
                        border: 2px solid #FFF !important;
                        opacity: 1;

                        svg{
                            path{
                                fill: #FFF !important;
                            }
                        }
                    }

                    .text{
                        color: #FFF !important;
                    }
                }
            }
        }
    }
    
    .menu-toggle-button{
        float: left;
        display: none;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 1em;
        margin-top: 9px;
        position: relative;
        color: $liteblue;
        z-index: 10;
        
            .fa{
                font-size: 1.5em;
                margin-right: 10px;
            }
            
            span{
                vertical-align: middle;
                display: table-cell;
            }
    }
    
    .user-profile{
        
        .log{
            text-decoration: none;
            padding-left: 15px;
            cursor: pointer;

            .team-icon{
                text-align: center;
                display: inline-block;
                width: 1.7em;
                height: 1.7em;
                border-radius: 100%;

                svg{
                    width: .8em;
                    height: .8em;
                    margin-top: 4px;
                }
            }
            
            .text{
                margin-left: 5px;
                text-transform: uppercase;
            }
        }
    }
}

.sidebar-menu{
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 100%;;
    text-align: center;
    z-index: 1000;
    width: 0;
    min-width: 0;
    visibility: hidden;
    color: #fff;
    width: 300px;
    visibility: visible;
    
    @include respond_to($screen){
        width: 0;
        visibility: hidden;

        .menu{
            display: none;
        }

        .company-logo{
            display: none;
        }
    }
    
    &.active{
        width: 300px;
        visibility: visible;

        .menu{
            display: block;
        }

        .company-logo{
            display: table;
        }
    }
    
    
    
    .close-icon{
        position: absolute;
        right: 30px;
        top: 20px;
        cursor: pointer;
        
        path{
            fill: #fff;
        }
    }
    
    .company-logo{
        display: table;
        margin-top: 80px;
    }
    
    h2{
        color: #fff;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    
    h4{
        margin-top: 10px;
        color: #fff;
    }
    
    .menu{
        padding: 0;
        text-align: left;
        margin-top: 74px;
        transition: .1s ease all;
        display: block;
        
        li{
            margin: 15px 0;
            width: 100%;
            white-space: nowrap;
            border-left: 4px solid transparent;
            transition: .2s ease all;
            cursor: pointer;
            
            &:hover{
              
                div{
                    color: #fff;

                    path{
                        fill: #fff;
                    }
                }
            }
            
            &.active{
                
                div{
                    color: #fff;
                    transition: .2s ease all;
                    
                    path{
                        fill: #fff;
                        transition: .2s ease all;
                    }
                }
            }
            
            a{
                padding: 8px 15%;
                display: block;
                
                div{
                    color: rgba(#fff,.6);
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 20px;

                    path{
                        fill: rgba(#fff,.6);
                    }

                    &.location-icon, &.profile-icon, &.question-icon{
                        height: 30px;
                        width: 30px;
                        border: 3px solid;
                        border-radius: 100%;

                        svg{
                            margin: 0 auto;
                            margin-top: 4px;
                            display: block;
                            transition: 2s ease all;

                            &.profile-svg{
                                width: 12px;
                                margin-top: 0px;
                            }
                        }
                    }
                    
                    &.star-icon{
                        height: 30px;
                        width: 30px;
                        border: 3px solid;
                        border-radius: 100%;
                        
                        svg{
                            margin: 0 auto;
                            margin-top: 4px;
                            display: block;
                            transition: 2s ease all;
                            height: 15px; width: 15px;
                        }
                    }
                }

                span{
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1.1em;
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }
    }
}

.background-mask{
    &.active{
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        left: 0; right: 0; top: 0; bottom: 0;
        z-index: 100;
    }
}