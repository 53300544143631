/* Content */

.content {
    font-family: $sans;
    float: inherit;
    padding: 50px 0;
    overflow: auto;

    &.callout {
        text-transform: uppercase;
        text-align: center;
        background-color: $litegrey;
        color: $gray;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        padding: 15px 0;
        @include respond_to($phone) {
            position: relative;
            bottom: initial;
            left: initial;
            right: initial;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            letter-spacing: 1px;
            margin-right: 30px;
            @include respond_to($phone) {
                margin-right: 0;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        ul {
            display: inline-block;
            vertical-align: middle;
            @include respond_to($phone) {
                margin-top: 10px;
            }

            li {
                display: table-cell;
                vertical-align: middle;
                padding-right: 30px;
                position: relative;
                &:last-child {
                    padding-right: 0;
                }
                @include respond_to($smallphone) {
                    display: block;
                    padding: 5px 0;
                }

                &.google {
                    top: 3px;
                }

                svg {
                    path {
                        fill: $gray !important;
                    }
                }
            }
        }
    }

    &.centered-content {
        padding: 100px 0;
        text-align: center;
        width: 100%;
        background-color: #fff;
        @include respond_to($phone) {
            padding: 50px 0;
        }

        h1 {
            font-size: 4em;
            @include respond_to($tablet) {
                font-size: 3.4em;
            }
        }

        h2 {
            color: #b0b5bf;
            opacity: 0.7;
            margin-top: 7px;
            margin-bottom: 30px;
        }

        &.text-white {
            h1 {
                color: #fff;
            }
            .icons {
                .group {
                    .icon {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }

        &.text-gray {
            h1 {
                color: $darkgray;
            }
            .icons {
                .group {
                    .icon {
                        svg {
                            path {
                                fill: $darkgray;
                            }
                        }
                    }
                }
            }
        }

        &.text-blue {
            .icons {
                .group {
                    .icon {
                        svg {
                            path {
                                fill: $liteblue;
                            }
                        }
                    }
                }
            }
        }

        &.text-green {
            .icons {
                .group {
                    .icon {
                        svg {
                            path {
                                fill: url(#green-gradient-svg);
                            }
                        }
                    }
                }
            }
        }

        .icons {
            padding: 1.5em 0;
            display: flex;
            overflow: hidden;
            justify-content: space-around;
            @include respond_to($phone) {
                display: block;
            }

            .group {
                vertical-align: top;
                padding: 0 5%;

                @include respond_to($phone) {
                    width: 100%;
                    display: block;
                    margin: 30px auto;
                }

                &:hover {
                    svg {
                        -webkit-transform: scale(1.15);
                        -moz-transform: scale(1.15);
                        -o-transform: scale(1.15);
                        transform: scale(1.15);
                    }
                }

                .icon {
                    margin: 0 auto;
                    margin-bottom: 1.25em;
                    position: relative;
                    background-position: center;
                    background-size: cover;

                    svg {
                        vertical-align: middle;
                        transition: ease 0.3s all;
                        height: 8vh;
                        width: 8vh;
                        @include respond_to($phone) {
                            height: 80px;
                            width: 80px;
                        }

                        path {
                            fill: #fff;
                        }
                    }

                    &:before {
                        content: " ";
                        position: absolute;
                        z-index: 10;
                        border-radius: 15px;
                        top: -5px;
                        left: -5px;
                        right: -5px;
                        bottom: -5px;
                        border: 2px solid transparent;
                        transition: 0.5s ease all;
                    }
                }

                .heading {
                    margin: 0 auto;
                    padding: 5px 10px;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: red;
                    letter-spacing: 2px;
                    border-radius: 4px;
                    transition: 0.5s ease all;
                    border: 2px solid transparent;
                }

                p {
                    margin: 15px auto;
                    font-size: 0.89em;
                    letter-spacing: 0.5px;
                    font-family: $sans;
                    color: $text;
                    @include respond_to($phone) {
                        width: 60%;
                    }
                }
            }
        }

        img {
            height: 40vh;
            display: block;
            margin: 1.5em auto;
            @include respond_to($phone) {
                height: auto;
            }
        }
    }

    &.half_content_image {
        padding: 0;
        position: relative;
        @include respond_to($phone) {
            padding: 50px 0;
        }

        h1 {
            font-size: 4em;
            @include respond_to($tablet) {
                font-size: 3.4em;
            }
        }

        h3 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        &.text-white {
            h1 {
                color: #fff;
            }

            h3 {
                color: rgba(255, 255, 255, 0.5);
            }

            .paragraph {
                color: #fff;
            }
        }

        &.white-gradient {
            .paragraph {
                color: $text;
            }
        }

        &.position-right {
            .wrap {
                .text {
                    right: 0;
                    left: initial;
                    padding-left: 2.5%;
                }
            }

            .image {
                left: 0;
                right: initial;

                img {
                    left: 0;
                    right: initial;
                }
            }
        }

        .wrap {
            height: 100%;

            .text {
                float: left;
                width: 47.5%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @include respond_to($phone) {
                    width: 100%;
                    float: none;
                    position: relative;
                    top: 0;
                    transform: none;
                }

                .paragraph {
                    font-family: $sans;
                    line-height: 1.5;
                }

                a {
                    margin: 1.5em auto;
                }
            }
        }

        .image {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 47.5%;
            margin: auto;
            height: 120%;
            overflow: hidden;
            @include respond_to($phone) {
                position: relative;
                width: 100%;
                margin-top: 20px;
            }

            img {
                position: absolute;
                margin: auto;
                display: table;
                right: 0;
                @include respond_to($phone) {
                    position: relative;
                }

                &.bottom {
                    bottom: 0;
                    position: absolute;
                }
                &.top {
                    top: 0;
                    position: absolute;
                }
                &.center {
                    bottom: 0;
                    top: 0;
                }
            }
        }
    }

    &.narrow {
        max-width: 600px;
        margin: auto;
    }

    &.home {
        .wrap {
            .text {
                @include respond_to($phone) {
                    margin-top: 0;
                }
            }
        }
    }

    &.page {
        h1 {
            font-size: 4em;
            line-height: 1.15;
            @include respond_to($tablet) {
                font-size: 3.4em;
            }
            @include respond_to($phone) {
                font-size: 2.3em;
            }
        }

        &.half_content_image {
            padding: 100px 0;
            position: relative;

            .wrap {
                height: 100%;

                .text {
                    position: relative;
                    left: initial;
                    top: initial;
                    transform: initial;
                }
            }

            .image {
                display: block;
                padding: 100px 0;
                img {
                    position: initial;
                    display: block;
                    margin: initial;
                    float: right;
                }

                &.bottom {
                    height: 85%;
                    margin: initial;
                    top: initial;

                    img {
                        position: absolute;
                    }
                }
                &.top {
                    height: 85%;
                    margin: initial;
                    bottom: initial;

                    img {
                        position: absolute;
                    }
                }
            }
        }
    }

    &.devices {
        height: 100vh;

        .column {
            .logo {
                position: absolute;
                top: -10vh;
            }

            &:first-child {
                h1 {
                    color: #fff;
                }
            }
            &:last-child {
                h1 {
                    color: $liteblue;
                }
            }

            .btn {
                margin-top: 20px;
            }
        }

        .register-device {
            margin-top: 25%;
            text-align: center;

            .mdl-textfield {
                max-width: 400px;
                margin: auto;
                display: block;
                text-align: center;
            }
        }
    }
}

main {
    position: relative;

    &.back-end {
        background-color: #fbfbfc;
        padding-bottom: 100px;

        @include respond_to($phone) {
            width: initial;
            float: initial;
        }
    }

    &.manage-location {
        .content {
            overflow: initial;
            margin: auto;
            @include respond_to($phone) {
                width: 90%;
            }
        }

        .location {
            display: inline-block;
            margin-top: 25px;
            margin-bottom: 25px;
            position: relative;
            border: 4px solid $litegrey;
            border-radius: 5px;
            text-decoration: none;

            &:nth-child(odd) {
                margin-left: 0;
                clear: both;
            }

            .google-map {
                width: 100%;
            }

            .company-logo {
                position: absolute;
                top: -30px;
                left: -30px;
            }

            .location-footer {
                padding: 25px 20px;
                display: block;

                h3 {
                    margin: 0;
                    display: inline-block;
                    color: $darkgray;
                }

                .star-rating {
                    float: right;
                    display: inline-block;
                }
            }

            &.new-location {
                .google-map,
                .location-footer {
                    opacity: 0.2;
                }

                .add-location-icon {
                    position: absolute;
                    z-index: 10;
                    top: 20%;
                    left: 30%;
                }
            }
        }
    }

    &.location {
        .location-header {
            text-align: center;

            .background-image {
                height: 225px;
                background-position: center;
                background-size: cover;
            }

            .group {
                margin-top: -125px;

                h1 {
                    margin-bottom: 0;
                    font-size: 2.2em;
                }

                .star-rating {
                    margin: 10px auto;
                }
            }
        }

        .col-1-2 {
            background-color: #fff;
            border: 2px solid $litegrey;
            padding: 50px 5%;
        }

        .edit-location {
            content: "";
            @include respond_to($phone) {
                border-right: none;
                padding-right: 0;
            }

            .btn {
                cursor: pointer;
                margin-bottom: 40px;
            }
        }

        .manage-reviews {
            a {
                cursor: pointer;
                margin-bottom: 40px;
            }

            .recent-reviews {
                .recent-review {
                    padding-bottom: 20px;
                    margin-bottom: 40px;
                    border-bottom: 1px solid $litegrey;
                    &:last-child {
                        border-bottom: none;
                    }

                    .name {
                        display: inline-block;
                    }

                    .star-rating {
                        float: right;
                    }
                }
            }
        }
    }

    &.dashboard {
        hgroup {
            margin-top: 50px;

            h2 {
                margin-bottom: 15px;
            }

            h4 {
                margin-top: 15px;
            }
        }

        .summary {
            overflow: hidden;
            margin-top: 50px;
            @include respond_to($phone) {
                text-align: center;
            }

            .latest-feedback {
                float: left;
                //display: inline-block;
                text-align: center;
                @include respond_to($phone) {
                    float: none;
                }

                h4 {
                    font-size: 1rem;
                    margin-top: 15px;
                }
            }

            .total-reviews {
                float: right;
                display: inline-block;
                text-align: center;
                @include respond_to($phone) {
                    float: none;
                    margin-top: 25px;
                }

                h1 {
                    font-family: $sans;
                    color: $green;
                    font-size: 75px;
                    margin: 0;

                    &,
                    * {
                        line-height: 1;
                    }

                    &:not(.odometer) {
                        padding: 4px 0;
                    }
                }

                h4 {
                    margin-top: 5px;
                }
            }
        }

        .request-callout {
            overflow: hidden;
            padding: 50px 0;
            text-align: center;
            @include respond_to($phone) {
                text-align: center;
            }

            h4 {
                display: inline-block;
                margin: 0;
                @include respond_to($phone) {
                    display: block;
                }
            }

            a {
                margin-left: 25px;
                @include respond_to($phone) {
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
        }

        .reviews {
            padding: 50px 5%;
            &:last-child {
                @include respond_to($phone) {
                    margin-top: 20px;
                }
            }

            h2 {
                margin: 0;
                margin-bottom: 50px;
                text-align: center;
            }

            .recent-review {
                margin-bottom: 40px;
                padding-bottom: 40px;
                border-bottom: 1px solid $litegrey;
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

                h2 {
                    text-align: center;
                }

                .name {
                    display: inline-block;
                }

                .logo {
                    float: right;
                    margin-left: 15px;
                }

                .star-rating {
                    float: right;
                }

                .location-info {
                    margin-top: 20px;
                    text-transform: uppercase;
                    font-size: 0.95em;
                    color: $darkgray;
                    font-family: $sans;

                    .date {
                        &:before {
                            content: "";
                        }
                    }

                    .divider {
                        color: $litegrey;
                        opacity: 0.7;
                        margin: 0 5px;
                    }
                }

                p {
                    margin: 13px auto;
                }
            }
        }
    }

    &.add-location {
        .column {
            display: block;
            margin-top: 100px;

            &.narrow {
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.profile {
        text-align: center;

        .tabbed-section {
            display: none;

            &.active {
                display: block;
            }

            .billing-locations {
                text-align: left;

                .billing-location {
                    margin-bottom: 50px;
                }

                .billing-cycle {
                    margin-bottom: 10px;
                }
            }

            form[name="manage_profile_form"] {
                text-align: left;
            }
        }

        .tabbed-selection {
            padding: 1em 0;
            margin-bottom: 1em;
            display: inline-block;
            border-bottom: 2px solid $litegrey;

            li {
                display: inherit;

                a {
                    display: block;
                    padding: 8px 25px;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $gray;
                    font-weight: bold;
                    font-family: $sans;
                    letter-spacing: 2px;
                }

                &.active {
                    a {
                        color: $green;
                    }
                }
            }
        }

        img {
            max-width: 100%;
            max-height: 200px;
        }
    }

    &.customers {
        h2 {
            text-align: center;
        }

        .dataTables_wrapper {
            font-family: $sans;
            color: $text;
            padding: 25px 0;

            table,
            th {
                border-bottom: 2px solid $liteblue;
            }

            th,
            .dataTables_info {
                color: $darkgray !important;
                text-transform: uppercase;
            }

            .dataTables_length {
                display: none;
            }

            .paginate_button {
                background: -webkit-linear-gradient(
                    right,
                    $gradient-blue-light,
                    $gradient-blue-dark
                ) !important;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;

                &:hover,
                &:active,
                &:focus {
                    box-shadow: initial !important;
                    border: initial !important;
                }

                &.current {
                    border: 2px solid $liteblue !important;
                }
            }

            .ellipsis {
                color: $liteblue;
            }
        }
    }
}

.lightbox-content {
    display: none;
}

.company-logo {
    padding: 4px;
    border: 4px solid $litegrey;
    height: 150px;
    width: 150px;
    background-color: #fff;
    z-index: 10;
    border-radius: 100%;
    text-align: center;
    margin: auto;
    display: table;
    position: relative;

    .logo {
        display: table-cell;
        vertical-align: middle;
        background-size: 90% auto;
        border-radius: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .upload {
        position: absolute;
        top: -4px;
        left: -4px;
        height: 150px;
        width: 150px;
        border-radius: 100%;
        border: 4px solid $litegrey;
        display: none;
        color: #fff;
        text-decoration: none;
        cursor: pointer;

        i {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &:hover {
        .upload {
            display: table;
            background: rgba(0, 0, 0, 0.6);
        }
    }
}

.breadcrumbs {
    background-color: $litegrey;
    text-transform: uppercase;
    font-weight: normal;
    padding: 25px 0;
    letter-spacing: 0.5px;
    font-family: $sans;
    @include respond_to($phone) {
        display: none;
    }

    a.breadcrumb-link {
        text-decoration: none;
        color: $darkgray;
        opacity: 0.5;
    }

    .current {
        color: $darkgray;
    }

    .divider {
        margin: 0 20px;
    }
}

#in-store-form {
    padding-bottom: 500px;
    @include respond_to($tablet) {
        margin-top: -30px;
    }
    @include respond_to($phone) {
        padding-bottom: 0px;
        margin-top: 0px;
    }

    .in-store-header {
        hgroup {
            margin: 40px 0;
            @include respond_to($tablet) {
                margin-top: 10px;
            }
            @include respond_to($phone) {
                margin-top: 40px;
            }

            h1 {
                font-size: 5em;
                margin: 0;
                margin-bottom: 15px;
                letter-spacing: 2px;
                @include respond_to($phone) {
                    font-size: 3em;
                }
            }

            h3 {
                font-size: 2.3em;
                letter-spacing: 2px;
                margin: 0;
                @include respond_to($phone) {
                    font-size: 1.6em;
                }
            }
        }
    }

    .in-store-form {
        .step-2 {
            display: none;

            .field {
                text-align: center;
                margin: 60px 0;

                .select-star-rating {
                    svg {
                        height: 100px;
                        width: 100px;
                        @include respond_to($phone) {
                            height: 60px;
                            width: 60px;
                        }
                    }
                }
            }
        }
        .step-3 {
            display: none;
            text-align: center;

            svg {
                cursor: pointer;
                path {
                    fill: $darkgray;
                }
            }
        }
    }
}

#login-form {
    .login-remember,
    .login-forgot {
        font-family: $sans;
        color: $gray;
        font-weight: normal;
        font-size: 0.75em;
        float: left;
        text-align: left;
        display: inline-block;
    }

    .login-forgot {
        margin-right: 10px;
    }

    .forgot-password {
        position: relative;
        top: -20px;
        margin-right: 10px;
        padding-left: 4%;
        overflow: auto;
    }
}

#rpm-video {
    position: relative;
    .background-logo {
        background-image: url("https://www.maxfeedback.com/wp-content/uploads/2016/12/radair_logo_video_overlay.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-color: rgba(47, 58, 62, 0.65);
        z-index: 10;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: contain;
    }

    video {
        position: fixed;
        height: 100%;
        z-index: -1;
    }
}

#login-page {
    .icon {
        display: none;
    }
}

#gform_8 {
    ul {
        marign: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 10px;

        #field_8_1,
        #field_8_2 {
            display: none;
        }
    }
}

.activate {
    h2 {
        background: -webkit-linear-gradient(
            left,
            $gradient-green-light,
            $gradient-green-dark
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .video-screen {
        width: 100%;
        height: 100vh;
        text-align: center;
        padding-top: 10vh;
        transition: 0.5s ease all;
        width: 100%;
        overflow: auto;

        &.hide {
            height: 0;
            padding-top: 0;
        }

        h1 {
            color: #fff;
            margin: 0.75em 0;
        }

        .video-section {
            display: block;
            margin: auto;
            width: 80%;
            max-width: 640px;

            iframe {
                @include respond_to($tablet) {
                    max-height: 30vh;
                }
            }

            .btn-group {
                display: block;
                text-align: right;
                margin-top: 15px;
                @include respond_to($phone) {
                    text-align: center;
                }

                span {
                    margin-right: 10px;
                    font-size: 0.75em;
                    color: #fff;
                    font-family: $sans;
                    position: relative;
                    top: 16px;
                    @include respond_to($phone) {
                        display: block;
                        top: 0;
                        margin-bottom: 10px;
                        margin-right: 0;
                    }

                    a {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .column {
        display: none;
        @include respond_to($phone) {
            height: 70vh !important;
            width: 112% !important;
            left: -6%;
        }

        &.active {
            display: block;
        }

        &:first-child {
            .logo {
                position: absolute;
                top: -12.5vh;
                height: 6vh;
                left: 0;
                @include respond_to($phone) {
                    position: relative;
                    top: 0;
                    margin-bottom: 3vh;
                }
            }

            .typing-section {
                @include respond_to($phone) {
                    top: 12vh;
                }
            }

            .progress-bar {
                position: absolute;
                top: 50vh;
                left: 0;
                right: 20%;
                height: 2px;
                @include respond_to($tablet) {
                    top: 350px;
                }
                @include respond_to($phone) {
                    top: 50vh;
                    right: 5%;
                    left: 5%;
                }

                .progress-text,
                .phone-number {
                    text-align: center;
                    display: block;
                    margin-top: 2.5vh;
                    font-weight: bold;
                    color: rgba(#fff, 0.7);
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }

                .phone-number {
                    text-transform: initial;
                    font-size: 0.9em;
                }

                ul {
                    height: 2px;
                    width: 100%;
                    background-color: rgba(#fff, 0.6);
                    display: flex;
                    justify-content: space-between;
                    transition: all 0.3s ease;

                    li {
                        width: 6px;
                        height: 6px;
                        background-color: #fff;
                        border-radius: 50%;
                        margin-top: -2px;
                    }

                    &:before,
                    &:after {
                        content: "";
                        width: 6px;
                        height: 6px;
                        background-color: #fff;
                        border-radius: 50%;
                        margin-top: -2px;
                    }
                }

                &:after {
                    content: "";
                    background-color: #fff;
                    width: 0%;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &.step-2 {
                    &:after {
                        width: 20%;
                    }
                }

                &.step-3 {
                    &:after {
                        width: 40%;
                    }
                }

                &.step-4 {
                    &:after {
                        width: 60%;
                    }
                }

                &.step-5 {
                    &:after {
                        width: 80%;
                    }
                }

                &.step-6 {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        &:last-child {
            #fullpage {
                .section {
                    @include respond_to($tablet) {
                        display: none;
                        margin: auto;
                        width: 95%;
                        &.active {
                            display: block;
                        }
                    }

                    #select_number_of_locations {
                        text-align: center;

                        .number_of_locations {
                            display: block;
                            border: none;
                            padding: 10px;
                            font-family: $sans;
                            width: 50%;
                            transition: 0.2s ease all;
                            background: 0 0;
                            color: inherit;
                            font-size: 6em;
                            text-align: center;
                            margin: auto;
                            font-weight: bold;
                            background: -webkit-linear-gradient(
                                right,
                                $gradient-green-light,
                                $gradient-green-dark
                            );
                            background: -o-linear-gradient(
                                right,
                                $gradient-green-light,
                                $gradient-green-dark
                            ); /* For Opera 11.1 to 12.0 */
                            background: -moz-linear-gradient(
                                right,
                                $gradient-green-light,
                                $gradient-green-dark
                            ); /* For Firefox 3.6 to 15 */
                            background: linear-gradient(
                                right,
                                $gradient-green-light,
                                $gradient-green-dark
                            ); /* Standard syntax */
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        input[name="number_of_locations"] {
                            display: none;
                        }

                        svg {
                            @include user_select(none);
                            path {
                                fill: $gray;
                            }
                        }
                    }

                    #incorrect-info {
                        clear: both;
                        float: right;
                        margin-top: 20px;
                        @include respond_to($phone) {
                            float: none;
                        }
                    }

                    .btn-group {
                        float: right;
                        margin-top: 40px;
                        @include respond_to($phone) {
                            float: none;
                        }
                    }

                    .previous-step {
                        color: $gray;
                        opacity: 0.7;
                        text-decoration: underline;
                        margin-right: 20px;
                        font-family: $sans;
                        letter-spacing: 0.5px;
                        @include user_select(none);
                        @include respond_to($tablet) {
                            margin-bottom: 10px;
                            display: block;
                        }
                    }

                    h3 {
                        color: $liteblue;
                        font-size: 0.95em;
                        span {
                            color: $gray;
                            text-transform: uppercase;
                            font-size: 0.9em;
                            opacity: 0.7;
                            letter-spacing: normal;
                            font-weight: normal;
                        }

                        &#upload_logo_header {
                            margin-top: 1.5em;
                            margin-bottom: 2em;
                        }
                    }
                    #upload_logo {
                        display: block;
                        text-transform: none;
                        font-weight: normal;
                        @include respond_to($phone) {
                            display: inline-block;
                        }
                    }

                    .review-service-buttons {
                        .button-row {
                            width: 100%;
                            height: 45px;
                            position: relative;
                            margin: 20px auto;

                            .logo {
                                width: 33%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                text-align: center;

                                &.logo-google {
                                    svg {
                                        path {
                                            fill: #3269e8 !important;
                                        }
                                    }
                                }

                                &.logo-facebook {
                                    svg {
                                        path {
                                            fill: #4267b1 !important;
                                        }
                                    }
                                }

                                &.logo-yelp {
                                    svg {
                                        path {
                                            fill: #d32323 !important;
                                        }
                                    }
                                }
                            }

                            .card {
                                position: absolute;
                                left: 40%;
                                top: 0;
                                bottom: 0;
                            }

                            .btn {
                                position: absolute;
                                left: 75%;
                                top: 0;
                                bottom: 0;
                                min-width: 0;
                                transition: ease all 0.3s;
                                &.btn-google {
                                    background-color: #3269e8;
                                    border: 2px solid #3269e8;
                                    &.link-off {
                                        background-color: #fff;
                                        svg {
                                            path {
                                                fill: #3269e8;
                                            }
                                        }
                                    }
                                }

                                &.btn-facebook {
                                    background-color: #4267b1;
                                    border: 2px solid #4267b1;
                                    &.link-off {
                                        background-color: #fff;
                                        svg {
                                            path {
                                                fill: #4267b1 !important;
                                            }
                                        }
                                    }
                                }

                                &.btn-yelp {
                                    background-color: #d32323;
                                    border: 2px solid #d32323;
                                    &.link-off {
                                        background-color: #fff;
                                        color: #d32323;
                                        svg {
                                            path {
                                                fill: #d32323 !important;
                                            }
                                        }
                                    }
                                }

                                &.toggle {
                                    display: none;
                                }
                            }

                            input[type="url"] {
                                display: none;
                            }
                        }
                    }

                    .pricing-area {
                        &:first-child {
                            padding-bottom: 1.5em;
                            margin-bottom: 1.5em;
                            border-bottom: 2px solid #ecedf0;
                        }

                        .left {
                            width: 35%;
                            margin-right: 4%;
                            display: inline-block;
                            text-align: center;

                            img {
                                display: block;
                                margin: auto;
                            }

                            h3 {
                                display: inline-block;
                                font-size: 0.9em;
                                margin: 1em 0;

                                span {
                                    display: block;
                                    margin-top: 0.5em;
                                    font-weight: normal;
                                    color: $liteblue;
                                    letter-spacing: 0.5px;
                                }
                            }
                        }

                        .right {
                            width: 55%;
                            margin-left: 4%;
                            display: inline-block;
                            vertical-align: top;

                            h2 {
                                display: inline-block;
                                font-size: 1.1em;
                                margin-top: 0;
                                margin-bottom: 15px;
                            }

                            ul {
                                font-family: $sans;
                                font-size: 0.9em;

                                li {
                                    position: relative;
                                    line-height: 1;
                                    margin-left: 8%;
                                    color: #abb1bd;

                                    &.check {
                                        &:before {
                                            content: "\f00c";
                                            font-family: FontAwesome;
                                            font-size: 1em;
                                            position: absolute;
                                            top: 0;
                                            left: -10%;
                                            margin: auto;
                                            display: table;
                                            transition: all 0.6s ease;
                                            background: -webkit-linear-gradient(
                                                right,
                                                $gradient-green-light,
                                                $gradient-green-dark
                                            );
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                        }
                                    }

                                    &.plus {
                                        &:before {
                                            content: "\f067";
                                            font-family: FontAwesome;
                                            font-size: 1em;
                                            position: absolute;
                                            top: 0;
                                            left: -10%;
                                            margin: auto;
                                            display: table;
                                            transition: all 0.6s ease;
                                            background: -webkit-linear-gradient(
                                                right,
                                                $gradient-green-light,
                                                $gradient-green-dark
                                            );
                                            -webkit-background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                        }
                                    }
                                }
                            }

                            a {
                                float: left;
                                margin-top: 20px;
                            }
                        }
                    }

                    .specials {
                        text-align: center;

                        hgroup {
                            margin: 2.5em 0;

                            h1 {
                                margin-top: 0;
                            }

                            h3 {
                                margin-bottom: 0.5em;
                            }
                        }

                        a {
                            display: inline-block;
                            margin-top: 0;
                            &:last-child {
                                @include respond_to($screen) {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }

                    #submit_credit_card {
                        .card-display {
                            @include respond_to_height(650px) {
                                display: none;
                            }
                        }
                        .credit-card-caption {
                            text-align: center;
                            color: lighten($gray, 20);
                            font-size: 0.8em;
                            text-decoration: underline;
                        }

                        .fine-print {
                            text-align: right;
                            font-size: 0.8em;
                            text-decoration: none;
                            clear: both;
                            line-height: 1.5;

                            a {
                                color: lighten($gray, 20);
                            }
                        }

                        .btn {
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }
}

.split-bg {
    @include respond_to($phone) {
        height: 100vh;
        overflow: auto;
    }

    .column {
        margin-top: 20vh;
        float: left;
        width: 50%;
        height: 100vh;
        position: relative;
        @include respond_to_height(600px) {
            margin-top: 15vh;
        }
        @include respond_to($phone) {
            width: 100%;
            float: initial;
            margin-top: 0;
            text-align: center;
            padding: 5vh 0;
        }

        .fp-section {
            height: auto !important;
            min-height: 100%;
        }

        .static {
            position: absolute;
            top: 39vh;
            @include respond_to($tablet) {
                top: 29vh;
                position: relative;
            }
        }

        &:first-child {
            padding-right: 10%;
            @include respond_to($phone) {
                padding-right: 0;
            }

            .typing-section {
                right: 20%;
                left: 0;
                @include respond_to($phone) {
                    right: 0;
                }
            }
        }

        &:nth-child(2) {
            padding-left: 10%;
            @include respond_to($phone) {
                padding-left: 0;
            }
        }

        &.white {
            .typing-section {
                background: -webkit-linear-gradient(
                    right,
                    rgba(24, 206, 251, 0.96),
                    rgba(4, 146, 228, 0.96)
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .typing-section {
            position: absolute;
            top: 0;
            height: 37vh;
            @include respond_to($phone) {
                height: 200px;
                padding: 0 5%;
                top: 5vh;
            }

            .type-here {
                color: #fff;
                font-size: 4em;
                font-weight: bold;
                text-transform: uppercase;
                font-family: $sans;
                letter-spacing: 1px;
                line-height: 1.15;
                @include respond_to_height(600px) {
                    font-size: 3.5em;
                }
                @include respond_to($screen) {
                    font-size: 3.5em;
                }
                @include respond_to($tablet) {
                    font-size: 2.7em;
                }
            }
        }
    }
}

.front-page {
    .section {
        &:first-child {
            @include respond_to($phone) {
                margin-top: 75px;
                padding-top: 0 !important;
            }
            .wrap {
                @include respond_to($phone) {
                    max-width: none;
                    width: 100%;
                }
            }
        }

        .column {
            height: 68vh;
            margin-top: 5vh;
            @include respond_to($phone) {
                height: 350px;
                margin-top: 0;
            }

            &:first-child {
                @include respond_to($phone) {
                    height: auto;
                }

                .typing-section {
                    @include respond_to($phone) {
                        position: relative;
                        height: auto;
                        top: 0;
                        margin-bottom: 20px;
                    }
                }

                .static {
                    @include respond_to($phone) {
                        top: 0;
                    }
                }
            }

            &:nth-child(2) {
                .static {
                    @include respond_to($phone) {
                        top: 175px;
                    }
                    @include respond_to($smallphone) {
                        top: 210px;
                    }
                }
            }

            &.white {
                .typing-section {
                    background: none;
                    -webkit-background-clip: initial;
                    -webkit-text-fill-color: $liteblue;
                    @include respond_to($phone) {
                        left: 0;
                        right: 0;
                    }

                    .type-here {
                        color: $liteblue;
                    }
                }
            }
        }
    }
}

.devices {
    .column {
        @include respond_to($phone) {
            height: 50vh !important;
            width: 112% !important;
            left: -6%;
            padding-top: 10vh;
        }

        h1 {
            margin-bottom: 30px;
        }
    }
}
#verify-yelp-link,
#verify-facebook-link {
    padding: 30px;

    .button-row {
        text-align: center;
    }
}

#lostpasswordform {
    width: 92%;
}
