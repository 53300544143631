/* Footer */

footer.main { 
    background-color: #363a42;
    text-align: center;
    color: #56595f;
    text-transform: uppercase;
    font-weight: bold;
    clear: both;
    
    &.back-end{
        width: 80%;
        float: right;
        
        .content-bar{
            display: none;
        }
        
        @include respond_to($phone){
            width: initial;
            float: initial;
        }
    }
    
    .content-bar{
        padding: 30px 0;
        div{
            display: inline-block;
            width: 19.5%;
            a{
                color: #56595f;
                text-decoration: none;
            }
        }
    }
    
    .copyright{
        padding: 10px 0;
        background-color: #4a4e55;
        font-size: .75em;
        color: #707277;
    }
}