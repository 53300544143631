/* Hero.scss */

.hero {
	position: relative;
	z-index: 1;
        color: #fff;
        
	&.home {
		width: 100%;
		overflow: hidden;
		
                .hero-content{
                    position: relative;
                    height: 100%;
                
                    .slides {
                            position: relative;
                            z-index: 9;
                            height: 700px;

                            .slide {
                                    height: 100%;
                                    display: block;
                                    @include respond-to($phone){
                                        background-attachment: initial;
                                        background-position: center;
                                    }
                                    background-size: cover;
                                
                                .caption {
                                    text-align: center;
                                    position: absolute;
                                    top: 25%; left: 20%; right: 20%;

                                    h1{
                                        font-size: 4.5em;
                                        @include respond-to($tablet){
                                            font-size: 3.5em;
                                        }
                                        @include respond-to($phone){
                                            font-size: 2.5em;
                                        }
                                        text-transform: uppercase;
                                        letter-spacing: 5px;
                                        margin:0;
                                        text-shadow: 5px 9px 70px $darkgray;
                                        color: #fff;
                                    }
                                    p{
                                        color: #fff;
                                        width: 60%;
                                        margin-left: auto;
                                        margin-right: auto;
                                        line-height: 1.5;
                                    }
                                    @include respond-to($phone) {
                                        top: 20%; left: 5%; right: 5%; bottom: 0;
                                    }
                                }
                        }
                }
        }
    }
    
    &.page{
        background-size: cover;
        padding-top: 75px;
        
        hgroup{
            padding: 75px 0;
            @include respond-to($phone){
                padding: 50px 0;
            }
            
            h1{
                font-size: 4.2em;
                @include respond-to($tablet){
                    font-size: 3.5em;
                }
                @include respond-to($phone){
                    font-size: 2.5em;
                }
                text-transform: uppercase;
                letter-spacing: 5px;
                margin:0;
                color: #fff;
            }
        }
    }
}