$no_of_columns: 12;
$single_col: (100 - ($grid_padding * ($no_of_columns - 1))) / $no_of_columns;

@mixin get_column_width($n: 1) {
	width: ($single_col * $n) + ($grid_padding * ($n - 1));
}

[class*='col-'] {
	float: left;
	
	& + [class*='col-'] {
		margin-left: $grid_padding;
	}
	
	@media screen and (max-width: $phone), (max-device-width: $phone) {
		float: none !important;
		margin-left: 0 !important;
		width: 100% !important;
	}
}

.col-3-4 {
	@include get_column_width(9);
}

.col-2-3 {
	@include get_column_width(8);
}

.col-1-2 {
	@include get_column_width(6);
}

.col-1-3 {
	@include get_column_width(4);
}

.col-1-4 {
	@include get_column_width(3);
}

.row{
    clear: both;
    overflow: hidden;
}